import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/propertiKategori'

export default {
  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  getAll () {
    return httpClient.get(`${endpoint}/all`)
  },

  getAllWithDetails () {
    return httpClient.get(`${endpoint}/allWithDetails`)
  },

  getPropertiKategoriByProyek (idProyek) {
    return httpClient.get(`${endpoint}/getByProyek/${idProyek}`)
  },

  setPerProyek (params) {
    return httpClient.post(`${endpoint}/setPerProyek`, params)
  }
}
