<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Progress Dokumen Unit" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <vs-select class="w-full" autocomplete :value="null" v-model="data.id_proyek">
              <vs-select-item v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">No. Unit *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Unit" :value="data.no_unit" readonly :disabled="!data.id_proyek" @click="modalPropertiUnit.active = true"/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" :disabled="!data.id_proyek" @click="modalPropertiUnit.active = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Item Dokumen Proyek *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Dokumen" :value="data.nama_item_dokumen_proyek" readonly :disabled="!data.id_proyek" @click="modalItemDokumen.active = true"/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" :disabled="!data.id_proyek" @click="modalItemDokumen.active = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tanggal Pengajuan</label>
            <flat-pickr class="w-full" v-model="data.tgl_pengajuan"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tanggal Estimasi Selesai</label>
            <flat-pickr class="w-full" v-model="data.tgl_est_selesai"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tanggal Selesai</label>
            <flat-pickr class="w-full" v-model="data.tgl_selesai"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Status *</label>
            <vs-select class="w-full" :value="null" v-model="data.status">
              <vs-select-item v-for="(it, index) in ['PROSES', 'SELESAI']" :key="index" :value="it" :text="it"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">No. Dokumen *</label>
            <vs-input class="w-full" v-model="data.no_dokumen"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Deskripsi Pengajuan *</label>
            <vs-input class="w-full" v-model="data.keterangan_pengajuan"/>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan_status" />
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">File</label>
            <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" v-model="data.fileTemp" @change="data.file = $event.target.files[0]"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Foto Bukti *</label>
            <vs-input class="w-full" type="file" accept="image/*" v-model="data.fotoBuktiTemp" @change="data.foto_bukti = $event.target.files[0]"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full mt-base">
            <vs-checkbox v-model="data.is_ajb">Sudah AJB <span class="text-xs ml-2 opacity-75">(Centang untuk mengubah status unit menjadi AJB).</span></vs-checkbox>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal properti unit-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Properti Unit"
                :active.sync="modalPropertiUnit.active">
        <PropertiUnit
          :selectable="true"
          :externalFilter="filterPropertiUnit"
          @selected="onSelectedModalPropertiUnit"/>
      </vs-popup>

      <!--modal item item dokumen proyek-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Item Dokumen Proyek"
                :active.sync="modalItemDokumen.active">
        <ItemDokumenProyek
          :selectable="true"
          :externalFilter="filterItemDokumenProyek"
          @selected="onSelectedModalItemDokumenProyek"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import ProgressDokumenUnitRepository from '@/repositories/proyek/progress-dokumen-unit-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import PropertiUnit from '@/views/pages/master/properti-unit/PropertiUnit'
import ItemDokumenProyek from '@/views/pages/master/item-dokumen-proyek/ItemDokumenProyek'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'ProgressDokumenUnitAdd',
  components: {
    ValidationErrors,
    flatPickr,
    PropertiUnit,
    ItemDokumenProyek
  },
  props: ['isActive'],
  mounted () {
    this.getAllProyek()
  },
  computed: {
    filterPropertiUnit () {
      return this.data.id_proyek ? { id_proyek: this.data.id_proyek } : null
    },
    filterItemDokumenProyek () {
      return { id_proyek: this.data.id_proyek, group1: this.data.group1 }
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      modalPropertiUnit: {
        active: false
      },
      modalItemDokumen: {
        active: false
      },
      data: {
        group1: 'Dokumen Unit',
        id_proyek: null,
        is_ajb: false
      },
      proyeks: []
    }
  },
  methods: {
    onChangeProyek () {
      this.data.id_properti_unit = null
      this.data.no_unit = null
      this.data.id_item_dokumen_proyek = null
      this.data.nama_item_dokumen_proyek = null
    },

    onSelectedModalPropertiUnit (item) {
      this.data.id_properti_unit = item.id
      this.data.no_unit = item.no_unit
      this.modalPropertiUnit.active = false
    },

    onSelectedModalItemDokumenProyek (item) {
      this.data.id_item_dokumen_proyek = item.id
      this.data.nama_item_dokumen_proyek = item.nama
      this.modalItemDokumen.active = false
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData(this.data)
      ProgressDokumenUnitRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
