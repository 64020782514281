import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/itemDokumenProyek'

export default {
  get (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  getAllByProyek (idProyek) {
    return httpClient.get(`${endpoint}/allByProyek/${idProyek}`)
  },

  getAll () {
    return httpClient.get(`${endpoint}/all`)
  },

  getAllWithDetails () {
    return httpClient.get(`${endpoint}/allWithDetails`)
  },

  setPerProyek (params) {
    return httpClient.post(`${endpoint}/setPerProyek`, params)
  }
}
